import { useContext, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import { Context } from '../../DataStore';

import '../../styles/recorder/ComboCounter.scss';

const ComboCounter = forwardRef((props, ref) => {
    const { store } = useContext(Context);
    const [totalTime, setTotalTime] = useState(store.status.modal.data.magicAI ? '00:00' : '01:00');
    const [recording, setRecording] = useState(false);

    const refs = {
        timer: useRef(null),
        seconds: useRef(0)
    }

    const startCountDown = () => {
        // Set video timers with maximums
        setRecording(true);
        refs.seconds.current = store.status.modal.data.magicAI ? 0 : 60;
        const pad = (value) => value > 9 ? value : `0${value}`;
        refs.timer.current = setInterval(() => {
            const videoTimeString = `${(store.status.modal.data.magicAI || (!store.status.modal.data.magicAI && refs.seconds.current < 60)) ? pad(parseInt(refs.seconds.current / 60, 10)) : '00'}:${pad((store.status.modal.data.magicAI ? ++refs.seconds.current : --refs.seconds.current) % 60)}`;
            setTotalTime(videoTimeString);
        }, 1000);
    }

    const stopCountDown = () => {
        setRecording(false);
        clearInterval(refs.timer.current);
        setTotalTime(store.status.modal.data.magicAI ? '00:00' : '01:00');
    }

    useImperativeHandle(ref, () => ({
        startCountDown,
        stopCountDown
    }));
    
    return (
        <div className={`ComboCounter${props.visible ? ' active' : ''}`}>
            <div className="timeWrapper">
            <div className="recorderStatus">
              <div className={`statusShape${recording ? ' recording' : ' idle'}`} />
            </div>
            <div className="recorderTime">
              {totalTime}
            </div>
          </div>
        </div>
    );
});

export default ComboCounter;
