import { useState, useContext, useEffect } from 'react';
import { Context } from '../../DataStore';

import Loading from '../common/Loading';

import * as strings from '../../data/strings';
import * as constants from '../exports/constants';

import '../../styles/common/Keywords.scss';

const Keywords = (props) => {
    const { store, dispatch } = useContext(Context);
    const [loading, setLoading] = useState(false);

    const editKeywords = () => {
        const statusCopy = {
            ...store.status,
            modal: {
                active: true,
                action: 'editKeywords',
                data: {
                    title: strings.default[store.language].AssetsView.EditKeywords,
                    description: strings.default[store.language].AssetsView.EditKeywordsDescription,
                    aid: props.aid,
                    value: props.data,
                    assetType: props.update.assetType,
                    responseIndex: props.update.responseIndex !== undefined ? props.update.responseIndex : null
                }
            }
        }
        dispatch({
            type: 'status',
            data: statusCopy
        });
    }

    const keywordInsight = (keyword) => {
        setLoading(true);
        props.keywordInsight(keyword);
    }

    useEffect(() => {
        if (props.loading === false && loading) {
            setLoading(false);
        }
    }, [loading, props.loading]);

    return (
        <div className="Keywords">
            <div className={`contentKeywords ${(props.mainPrompt !== undefined) ? ' mainPrompt' : ''}`}>
                <div className={`keywordTitle${(props.dark !== undefined) ? ' dark' : ''}`}>
                    <div className="keywordAI">
                        AI
                    </div>
                    <div className="keywordWord">
                        {`${strings.default[store.language].AssetsView.Keywords}`}
                        <span className="beta">
                            BETA
                        </span>
                    </div>
                    <div className={`loadWrapper${props.loading && loading ? ' active' : ''}`}>
                        <Loading />
                    </div>
                    {(props.update.allowed !== undefined && props.update.allowed) &&
                        <div className="editKeywords">
                            <button
                                className={`editKeywordsButton${(props.dark !== undefined) ? ' dark' : ''}`}
                                onClick={() => editKeywords()}>
                                {`${strings.default[store.language].AssetsView.EditKeywords}`}
                            </button>
                        </div>
                    }
                </div>
                <div className="keywordsWrapper">
                    {props.data.split(',').map((keyword, i) => {
                        if (constants.utils.cleanText(keyword).length > 0 && i < 5) {
                            return (
                                <div
                                    key={`Keyword${i}`}
                                    className="keywordWrapper">
                                    <button
                                        className="keyword"
                                        onClick={() => keywordInsight(keyword)}>
                                        {constants.utils.cleanText(keyword)}
                                    </button>
                                </div>
                            )
                        } else {
                            return '';
                        }
                    })}
                </div>
            </div>
        </div>
    );
}

export default Keywords;