import Header from '../common/Header';
import '../../styles/content/Privacy.scss';

const Privacy = () => {
    return (
        <div className="Privacy">
            <Header />
            <div className="header">
                <span className="title">Privacy Policy</span>
            </div>
            <div className="content">
                <span className="title">Effective Date: August 23, 2024</span><br/>
                <br/>
                <span className="title">1. Introduction</span><br/>
                <li>Welcome to Gravity, the asynchronous, AI-powered video communication platform designed for education and beyond.</li><br/>
                <li>Our commitment is to protect and respect your privacy. This policy describes the collection, use, storage, and disclosure of information.</li><br/>
                <br/>
                <span className="title">2. Information Collection</span><br/>
                <li>Personal Information: We securely collect only information required to maintain an account, such as name and email address.</li><br/>
                <li>Video Content: We securely store the video content you create in our cloud provider, and it is inaccessible outside of Gravity.</li><br/>
                <li>Usage Data: We collect anonymized data on how you use our platform, including access times, viewed pages, geographic region, and device information.</li><br/>
                <li>Student Records: For educational institutions, we collect and handle student records in compliance with FERPA.</li><br/>
                <li>Authentication Providers: When you use Google or other authentication providers to log in to our platform, we collect the information necessary to authenticate your account, such as your name, email address, and any other information you have permitted the provider to share with us.</li><br/>
                <br/>
                <span className="title">3. Use of Information</span><br/>
                <li>To communicate with you about your account and our services.</li><br/>
                <li>To comply with legal obligations, including COPPA and FERPA requirements.</li><br/>
                <li>To improve our services and user experience.</li><br/>
                <li>To authenticate your identity when you log in using Google or other authentication providers.</li><br/>
                <br/>
                <span className="title">4. Sharing of Information</span><br/>
                <li>With third-party service providers assisting our platform operation. These providers are contractually obligated to protect your information and comply with COPPA and FERPA.</li><br/>
                <li>As required by law or to protect our rights and safety.</li><br/>
                <li>With educational institutions, as needed to provide services and comply with FERPA.</li><br/>
                <li>With authentication providers like Google, only as necessary to provide authentication services and comply with their terms of service and privacy policies.</li><br/>
                <br/>
                <span className="title">5. Data Security</span><br/>
                <li>We implement security measures to protect your information from unauthorized access, alteration, and disclosure.</li><br/>
                <br/>
                <span className="title">6. Children's Privacy</span><br/>
                <li>Our platform is normally intended for use by individuals over the age of 13. We do not knowingly collect information from children under 13 without verifiable parental consent or teacher consent where allowed under COPPA.</li><br/>
                <li>If we become aware that we have collected personal information from a child under 13 without verifiable parental or teacher consent, we will take steps to delete that information.</li><br/>
                <li>Parents can contact us to review, delete, or stop the collection of their child's personal information by emailing hello@usegravity.io. We will verify the identity of the parent before complying with such requests.</li><br/>
                <br/>
                <span className="title">7. FERPA Compliance</span><br/>
                <li>We comply with FERPA by ensuring that student education records are kept confidential and are only disclosed with proper consent or as permitted by law.</li><br/>
                <li>Parents and eligible students have the right to access and seek corrections to their education records. To exercise these rights, contact us at hello@usegravity.io.</li><br/>
                <li>We take measures to protect the privacy of student education records in our possession, including secure storage and access controls.</li><br/>
                <br/>
                <span className="title">8. Parental and Teacher Consent Details</span><br/>
                <li>We obtain verifiable parental consent before collecting personal information from children under 13 through methods such as providing a consent form to be signed and returned or using a credit card transaction for verification.</li><br/>
                <li>In certain educational settings, teachers and schools may act in the place of parents to provide consent for the collection of personal information from children under 13, as permitted by COPPA.</li><br/>
                <li>Parents or teachers can provide consent by emailing us at hello@usegravity.io.</li><br/>
                <br/>
                <span className="title">9. Data Retention and Deletion</span><br/>
                <li>We retain personal information only as long as necessary to fulfill the purposes for which it was collected or as required by law.</li><br/>
                <li>When personal information is no longer needed, we securely delete it to prevent unauthorized access or use.</li><br/>
                <br/>
                <span className="title">10. Data Breach Response</span><br/>
                <li>We have procedures in place to respond to data breaches. In the event of a data breach, we will notify affected individuals and authorities as required by law.</li><br/>
                <br/>
                <span className="title">11. User Rights and Choices</span><br/>
                <li>Users have the right to access, update, or delete their personal information. To exercise these rights, contact us at hello@usegravity.io.</li><br/>
                <li>Users can opt out of data collection or withdraw consent at any time by contacting us.</li><br/>
                <br/>
                <span className="title">12. Cookie Policy</span><br/>
                <li>We use cookies and similar technologies to enhance your experience on our platform. Cookies are small text files stored on your device. They help us understand how to improve functionality.</li><br/>
                <li>You can manage your cookie preferences through your browser settings. Note that disabling cookies may affect the functionality of our platform.</li><br/>
                <br/>
                <span className="title">13. International Users</span><br/>
                <li>If you are accessing our platform from outside the United States, your information may be transferred to, stored, and processed in the United States or other countries where our servers are located.</li><br/>
                <li>We comply with international data protection laws, including GDPR, for users in the EU.</li><br/>
                <br/>
                <span className="title">14. US State Specific Compliance</span><br/>
                <li>Illinois, USA:</li><br/>
                <li>We comply with the Student Online Personal Protection Act (SOPPA) This includes providing parents with the rights to access, review, and correct student data.</li><br/>
                <li>We comply with the Biometric Information Privacy Act (BIPA) as no biometric information is collected or stored by the platform.</li><br/>
                <li>We comply with the Personal Information Protection Act (PIPA) as affected individulas will be promptly notified in the event of a data breach.</li><br/>
                <br/>
                <span className="title">15. Changes to This Policy</span><br/>
                <li>We may update this policy. We will notify you of any changes by posting the new policy on this page and, if the changes are significant, by providing a prominent notice on our website or sending an email notification.</li><br/>
                <br/>
                <span className="title">16. Contact Us</span><br/>
                <li>For any questions or concerns regarding this privacy policy or state-specific policies, please contact us at hello@usegravity.io.</li>
            </div>
        </div>
    );
}

export default Privacy;
