import { useContext, useState, useEffect } from 'react';
import { Context } from '../../DataStore';

import emoji from 'emoji-dictionary';
import * as constants from '../exports/constants';

import '../../styles/common/Reaction.scss';

const Reaction = (props) => {
  const { store, dispatch } = useContext(Context);
  const [trayOpen, setTrayOpen] = useState(false);
  const [existingEmojis, setExistingEmojis] = useState([]);

  const sendMessage = (data) => {
    const statusCopy = {
      ...store.status,
      message: {
        type: data.type,
        text: data.text
      }
    }
    dispatch({
      type: 'status',
      data: statusCopy
    });
  };

  const addEmoji = (emoji) => {
    let existingCopy = [...existingEmojis];

    const emojiIndex = existingCopy.map((emoji) => emoji.emoji).indexOf(emoji);
    
    const modifyEmoji = async (action) => {
      try {
        const data = {
          cid: store.status.community.cid,
          gid: store.status.group.gid,
          aid: props.aid,
          uuid: store.profile.uuid,
          emoji: emoji, 
          sessionId: store.session.sessionId
        }
        const url = `${constants.services.url.api}/reaction/${action}/`;
        const response = await fetch(url, constants.services.config(data));
        const responseData = await response.json();

        if (response.ok) {
          if (responseData.status !== 'Success') {
            sendMessage(constants.strings.messages('error', 'network'));
          }
        } else {
          sendMessage(constants.strings.messages('error', 'network'));
        }
      } catch (error) {
        sendMessage(constants.strings.messages('error', 'network'));
      }
    }

    if (emojiIndex > -1) {
      if (!existingCopy[emojiIndex].users.includes(store.profile.name)) {
        existingCopy[emojiIndex].users.push(store.profile.name);
        modifyEmoji('create');
      } else {
        const userIndex = existingCopy[emojiIndex].users.map(user => user).indexOf(store.profile.name);
        existingCopy[emojiIndex].users.splice(userIndex,1);
        modifyEmoji('remove');
      }
    } else {
      existingCopy.push({
        emoji: emoji,
        users: [store.profile.name]
      });
      modifyEmoji('create');
    }
    setExistingEmojis(existingCopy);
  }

  const emojis = [
    "+1",
    "-1",
    "raised_hands",
    "clap",
    "muscle",
    "heart_eyes",
    "sunglasses",
    "scream",
    "monocle",
    "partying",
    "sweat_smile",
    "joy",
    "zany",
    "fire",
    "sparkling_heart"
  ]

  useEffect(() => {
    if (props.data.length) {
      let temporary = [];
      for(let [index, data] of props.data.entries()) {
        const emojiIndex = temporary.map((emoji) => emoji.emoji).indexOf(data.emoji);
        
        if (emojiIndex > -1) {
          temporary[emojiIndex].users.push(data.name);
        } else {
          temporary.push({
            emoji: data.emoji,
            users: [data.name]
          });
        }
        if (index === props.data.length - 1) {
          setExistingEmojis(temporary);
        }
      }
    }
  },[props.data]);

  return (
    <div className={`Reaction${props.dark !== undefined ? ' dark' : ''}`}>
      <div className="reactions">
      {existingEmojis.length ?
      <div className="wrapper">
        {existingEmojis.map((symbol, i) => {
          return (
          symbol.users.length ?
          <button 
            key={`Emoji${i}`}
            className="emojiWrapper"
            onClick={() => addEmoji(symbol.emoji)}>
            <div className={`entityWrapper ${symbol.users.includes(store.profile.name) ? ' selected' : ''}`}>
              <div className="symbolWrapper">
                <div className="symbol">
                  {emoji.getUnicode(symbol.emoji)}
                </div>
              </div>
              <div className="countWrapper">
                <div className="count">
                  {symbol.users.length}
                </div>
              </div>
            </div>
          </button> : ''
          )
        })}
      </div> :
      ''
      }
      </div>
      <div className="addEmoji">
        <button 
          className={`openEmoji${trayOpen ? ' open' : ''}`}
          onClick={() => setTrayOpen(!trayOpen)}>
          + ☺
        </button>
      </div>
      {trayOpen &&
      <div 
        className="emojis"
        onMouseLeave={() => setTrayOpen(false)}>
        {emojis.map((symbol, i) => (
        <div 
          key={`Symbol${i}`}
          className="buttonWrapper">
          <button 
            className="emoji"
            disabled={existingEmojis.map((emoji) => emoji.emoji).indexOf(symbol) > -1 && existingEmojis[existingEmojis.map((emoji) => emoji.emoji).indexOf(symbol)].users.includes(store.profile.name)}
            onClick={() => addEmoji(symbol)}>
            {emoji.getUnicode(symbol)}
          </button>
        </div>
        ))}
      </div>
      }
    </div>
  );
}

export default Reaction;
