import { useContext, useState, useEffect } from 'react';
import { Context } from '../../DataStore';

import '../../styles/recorder/ActionBar.scss';

const ActionBar = (props) => {
  const { store, dispatch } = useContext(Context);
  const [loaded, setLoaded] = useState(false);

  const closeRecorder = () => {
    props.onClose();
    
    const statusCopy = {
      ...store.status,
      recorder: {
        ...store.status.recorder,
        active: false,
        responding: {
          active: false
        }
      }
    }

    dispatch({
      type: 'status',
      data: statusCopy
    });
  }

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
    }
  }, [loaded]);

  return (
    <div className="ActionBar">
      {(store.status.recorder.active) &&
        <div className="buttonTab">
          <div className="buttonWrapper">
            <button
              className={`button${store.status.recorder.active ? ' active' : ''}`}
              disabled={!store.status.meetings.selected || store.status.recorder.recording}
              onClick={() => closeRecorder()}>
              <div className="addWrapper delete">
                <div className={`plus vertical${store.status.recorder.active ? ' active' : loaded ? ' inactive' : ''}`}></div>
                <div className={`plus horizontal${store.status.recorder.active ? ' active' : loaded ? ' inactive' : ''}`}></div>
              </div>
              <div className="actionText">
                Close Recorder
              </div>
            </button>
          </div>
        </div>
      }
    </div>
  );
}

export default ActionBar;
