import ReactGA from 'react-ga4';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Context } from '../../DataStore';

import * as strings from '../../data/strings';
import * as constants from '../exports/constants';

import '../../styles/marketing/Hero.scss';

const Hero = (props) => {
  const { store } = useContext(Context);
  const history = useHistory();

  const goLogin = () => {
    if (!constants.services.devMode) {
      ReactGA.event({
        category: "Marketing",
        action: "Login - Header"
      });
    }
    history.push('/login/');
  }

  const goRegister = () => {
    if (!constants.services.devMode) {
      ReactGA.event({
        category: "Marketing",
        action: "Register - Header"
      });
    }
    history.push('/register/');
  }

  const schools = [
    {
      image: 'txstate',
      school: 'Texas State University'
    },
    {
      image: 'mccoy',
      school: 'McCoy College of Business Administration'
    },
    /*{
      image: 'minnesota',
      school: 'University of Minnesota'
    },*/
    {
      image: 'ahisd',
      school: 'Alamo Heights ISD'
    },
    {
      image: 'navasota',
      school: 'Navasota ISD'
    },
    {
      image: 'gravity-education',
      school: 'Gravity Academy'
    }
  ]

  return (
    <div className="Hero">
      <div className="menuBar">
        <img
          className="gravityLogo"
          src={`${process.env.REACT_APP_CF_MKT_ENDPOINT}svg/gravity-logo-white.svg`}
          alt="Gravity Logo" />
        {store.marketing.school !== null && store.marketing.school !== undefined &&
          <img
            className="schoolLogo"
            src={`${process.env.REACT_APP_CF_MKT_ENDPOINT}png/landing/${store.marketing.school}.png`}
            alt={`${store.marketing.school} logo`} />
        }
        <div className="buttonsWrapper">
          <div className="buttonWrapper">
            <button
              className="headerButton startHere"
              onClick={() => goRegister()}>
              {strings.default[store.language].Marketing.StartForFree}
            </button>
          </div>
          <div className="buttonWrapper">
            <button
              className="headerButton logIn"
              onClick={() => goLogin()}>
              {strings.default[store.language].Marketing.LogIn}
            </button>
          </div>
        </div>
      </div>
      <div className="wrapper">
        <div className="content">
          <div className="headerContent">
            <div className="backgroundGif" />
            <div className="gradientFade" />
            <div className="headerSection text">
              <div className="headerTextWrapper">
                <div className="headerText">
                  {strings.default[store.language].Marketing.HeroTitle}
                </div>
                <div className="headerSubtext">
                  <span className="bold">{strings.default[store.language].Marketing.HeroDescriptionBold}</span> {strings.default[store.language].Marketing.HeroDescription}
                </div>
              </div>
            </div>
            <div className="headerSection graphic">
              <div className="heroGraphic">
                <div className="graphicWrapper person1">
                  <img
                    className="graphic"
                    src={`${process.env.REACT_APP_CF_MKT_ENDPOINT}gif/person1${props.sector}.gif`}
                    alt="Customer happy" />
                </div>
                <div className="graphicWrapper person2">
                  <img
                    className="graphic"
                    src={`${process.env.REACT_APP_CF_MKT_ENDPOINT}gif/person2${props.sector}.gif`}
                    alt="Customer happy" />
                </div>
                <div className="graphicWrapper person3">
                  <img
                    className="graphic"
                    src={`${process.env.REACT_APP_CF_MKT_ENDPOINT}gif/person3${props.sector}.gif`}
                    alt="Customer happy" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="logoSlider">
        <div className="logoSlideTrack">
          {[0, 0].map(() => (
            schools.map((school, i) => (
              <div
                className="slide"
                key={`School${i}`}>
                <img
                  className="logo"
                  src={`${process.env.REACT_APP_CF_MKT_ENDPOINT}png/${school.image}.png`}
                  alt={school.school} />
              </div>
            ))
          ))}
        </div>
      </div>
    </div >
  );
}

export default Hero;
