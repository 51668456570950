import { useContext, useState, useEffect, useCallback, useRef } from 'react';
import { Context } from '../../DataStore';
import { useHistory, useParams } from "react-router-dom";

import * as constants from '../exports/constants';
import * as strings from '../../data/strings';

import Loading from '../common/Loading';

import '../../styles/dashboard/RecentVideos.scss';

const openIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/open.svg`;
const tumbleweedIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/tumbleweed.svg`;
const VoiceIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/voice.svg`;

const RecentVideos = () => {
  const { store, dispatch } = useContext(Context);
  const history = useHistory();
  const params = useParams();

  const [data, setData] = useState([]);
  const fetching = useRef(false);

  const sendMessage = useCallback((data) => {
    const statusCopy = {
      ...store.status,
      message: {
        type: data.type,
        text: data.text
      }
    }
    dispatch({
      type: 'status',
      data: statusCopy
    });
  }, [dispatch, store.status]);

  const fetchDashboard = useCallback(async () => {
    if (store.deeplink !== undefined && store.deeplink.params.groupId === undefined) {

      try {
        let data = {
          uuid: store.profile.uuid,
          sessionId: store.session.sessionId
        }
        const url = `${constants.services.url.api}/dashboard/fetch/videos/`;
        const response = await fetch(url, constants.services.config(data));
        const responseData = await response.json();
        fetching.current = false;

        if (response.ok) {
          if (responseData.status !== undefined && responseData.status === 'Success') {
            setData(responseData.data);
          }
        } else {
          sendMessage(constants.strings.messages('error', 'network'));
        }
      } catch (error) {
        fetching.current = false;
        sendMessage(constants.strings.messages('error', 'network'));
      }
    }
  }, [sendMessage, store.profile.uuid, store.session.sessionId, store.deeplink]);

  const loadVideo = (video) => {
    history.push(`/g/${video.cid}/${video.gid}/${video.sid}/${video.aid}`);
    window.location.reload();
  }

  useEffect(() => {
    if (!data.length && !fetching.current && !constants.utils.isObject(params)) {
      fetching.current = true;
      fetchDashboard();
    }
  }, [data.length, fetching, fetchDashboard, params, history.location.pathname]);

  return (
    <div className="RecentVideos dashboardModule topModule fullWidth">
      <div className="moduleWrapper">
        <div className="moduleName">
          {strings.default[store.language].Dashboard.RecentVideos}
        </div>
        <div className="moduleContent">
          <div className="moduleBox topModule">
            {data.length ?
              data.map((video, i) => (
                <div
                  className="videoWrapper"
                  key={`Video${i}`}>
                  <button
                    className="videoInner"
                    onClick={() => loadVideo(video)}>
                    {video.type !== 'text' &&
                    <div
                      className={`previewVideo${video.type === 'voice' ? ' voice' : ''}`}
                      style={{ backgroundImage: (video.type === 'voice' ? "" : `url("${constants.services.awsLocation(true)}/${video.aid}.gif")`) }}>
                      {video.type === 'voice' &&
                        <img
                          className="voiceIcon"
                          src={VoiceIcon}
                          alt="Voice" />
                      }
                      <div className="openImage">
                        <img
                          className="openIcon"
                          src={openIcon}
                          alt="Open video" />
                      </div>
                    </div>
                    }
                    <div className="previewTitle">
                      <div className="previewDate">
                        {constants.utils.parseDate(video.creation)}
                      </div>
                      <div className={`previewDescription ${video.type}`}>
                        {constants.utils.truncateString(video.description, video.type === 'text' ? 120 : 80)}
                        {video.type === 'text' &&
                          <div className="triangle" />
                        }
                      </div>
                      <div className="previewCreator">
                        {video.name}
                      </div>
                    </div>
                    <div className="previewIndicator" />
                  </button>
                </div>
              )) :
              <div className="updateEmpty">
                {fetching.current ?
                  <Loading active={true} /> :
                  <>
                    <div className="emptyImage">
                      <img
                        className="emptyIcon"
                        src={tumbleweedIcon}
                        alt="Nothing here" />
                    </div>
                    <div className="emptyText">
                      No videos yet
                    </div>
                  </>
                }
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default RecentVideos;
