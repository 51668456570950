import Header from '../common/Header';
import '../../styles/content/TermsOfService.scss';

const TermsOfService = () => {
    return (
        <div className="TermsOfService">
            <Header />
            <div className="header">
                <span className="title">Terms of Service</span>
            </div>
            <div className="content">
                <span className="title">Effective Date: July 1, 2024</span><br/>
                <br/>
                <span className="title">1. Acceptance of Terms</span><br/>
                <li>By accessing or using Gravity, you agree to be bound by these Terms of Service ("Terms"). If you do not agree to these Terms, do not use our services.</li><br/>
                <br/>
                <span className="title">2. Description of Service</span><br/>
                <li>Gravity provides an asynchronous AI-powered video communication platform primarily intended for educational purposes.</li><br/>
                <br/>
                <span className="title">3. User Obligations</span><br/>
                <li>You agree to use Gravity for lawful, non-commercial, educational purposes only.</li><br/>
                <li>You are responsible for all content you transmit through our platform.</li><br/>
                <li>You must respect the privacy and intellectual property rights of others.</li><br/>
                <br/>
                <span className="title">4. User Accounts</span><br/>
                <li>You must provide accurate and complete registration information.</li><br/>
                <li>You are responsible for safeguarding your account and should notify us immediately of any unauthorized use.</li><br/>
                <br/>
                <span className="title">5. Content Ownership and License</span><br/>
                <li>You retain all rights to the content you upload but grant Gravity a license to use, modify, display, and distribute your content in connection with the service.</li><br/>
                <li>Gravity reserves the right to remove any content that violates these Terms or applicable laws.</li><br/>
                <br/>
                <span className="title">6. Prohibited Conduct</span><br/>
                <li>You may not use the service to engage in illegal, harmful, or offensive activities.</li><br/>
                <li>You may not attempt to disrupt the service or exploit any vulnerabilities in the system.</li><br/>
                <br/>
                <span className="title">7. COPPA and FERPA Compliance</span><br/>
                <li>If you are a school or educator in the United States and provide students' personal information to Gravity, you represent and warrant that you have obtained appropriate parental consent as required under the Children's Online Privacy Protection Act (COPPA).</li><br/>
                <li>If you are an educational institution subject to the Family Educational Rights and Privacy Act (FERPA), you agree to designate Gravity as a "school official" with "legitimate educational interests" in the educational records as defined under FERPA.</li><br/>
                <br/>
                <span className="title">8. Termination</span><br/>
                <li>Gravity may terminate or suspend your access to the service for any violation of these Terms.</li><br/>
                <br/>
                <span className="title">9. Disclaimers and Limitation of Liability</span><br/>
                <li>The service is provided "as is" without warranties of any kind.</li><br/>
                <li>Gravity is not liable for any indirect, incidental, or consequential damages resulting from the use of the service.</li><br/>
                <br/>
                <span className="title">10. Modification of Terms</span><br/>
                <li>We reserve the right to modify these Terms at any time. Your continued use of the service following any changes indicates your acceptance of the new Terms.</li><br/>
                <br/>
                <span className="title">11. Governing Law</span><br/>
                <li>These Terms shall be governed by the laws of Delaware and the United States.</li><br/>
                <br/>
                <span className="title">12. Contact Information</span><br/>
                <li>For any questions or concerns regarding these Terms, please contact us at hello@usegravity.io</li>
            </div>
        </div>
    );
}

export default TermsOfService;
