import { useState, useEffect } from 'react';

const useSpeechSynthesis = () => {
    const [speaking, setSpeaking] = useState(false);
    const [utterance, setUtterance] = useState(null);

    const speak = (text, lang = 'en-US') => {
        if (window.speechSynthesis) {
            window.speechSynthesis.cancel();
            const newUtterance = new SpeechSynthesisUtterance(text);
            newUtterance.lang = lang;
            newUtterance.rate = 1.2;
            newUtterance.onstart = () => setSpeaking(true);
            newUtterance.onend = () => {
                setSpeaking(false);
                setUtterance(null);
            };
            window.speechSynthesis.speak(newUtterance);
            setUtterance(newUtterance);
        } else {
            console.error('Speech Synthesis API is not supported in this browser.');
        }
    };

    const pause = () => {
        if (window.speechSynthesis && speaking) {
            window.speechSynthesis.pause();
        }
    };

    const resume = () => {
        if (window.speechSynthesis && speaking) {
            window.speechSynthesis.resume();
        }
    };

    const cancel = () => {
        if (window.speechSynthesis) {
            window.speechSynthesis.cancel();
            setSpeaking(false);
            setUtterance(null);
        }
    };

    useEffect(() => {
        return () => {
            if (window.speechSynthesis) {
                window.speechSynthesis.cancel();
            }
        };
    }, []);

    return { speak, speaking, pause, resume, cancel };
};

export default useSpeechSynthesis;
