import { useContext } from 'react';
import { Context } from '../../DataStore';
import { useHistory } from 'react-router-dom';

import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";

import * as constants from '../exports/constants';

import '../../styles/common/Google.scss';

const Google = (props) => {
  const { store, dispatch } = useContext(Context);
  const history = useHistory();

  const sendMessage = ((data) => {
    const statusCopy = {
      ...store.status,
      message: {
        type: data.type,
        text: data.text
      }
    }
    dispatch({
      type: 'status',
      data: statusCopy
    });
  }, [dispatch, store.status]);

  const successLogin = async (googleData) => {
    let isMounted = true;

    try {
      const decodedData = jwtDecode(googleData.credential);
      /* 
      {
        aud: "918317489922-k4goqnquae3be7geskgvs3pbu7p2f4fv.apps.googleusercontent.com"
        azp: "918317489922-k4goqnquae3be7geskgvs3pbu7p2f4fv.apps.googleusercontent.com"
        email: "dcipoletti@gmail.com"
        email_verified: true
        exp: 1700368625
        family_name: "Cipoletti"
        given_name: "Daniel"
        iat: 1700365025
        iss: "https://accounts.google.com"
        jti: "a00cf0ead4934515081fe4d71c23e024b464a78c"
        locale: "en"
        name: "Daniel Cipoletti"
        nbf: 1700364725
        picture: "https://lh3.googleusercontent.com/a/ACg8ocLpZLeCk1BdnGNmwB-abHDMZUo6pOw9-SI4y4o9d17Dv94=s96-c"
        sub: "102451628062781913709"
      }
      */

      if (decodedData.email_verified) {
        let data = {
          email: decodedData.email,
          token: googleData.credential
        }

        const url = `${constants.services.url.api}/user/validate/google/`;
        const response = await fetch(url, constants.services.config(data));
        const responseData = await response.json();

        if (!isMounted) return;

        if (response.ok) {
          if (responseData.status === 'Success') {
            // If user authenticated but does not exist - push to register
            if (responseData.exists !== undefined && !responseData.exists) {
              localStorage.setItem('gravitySocialData', JSON.stringify({
                email: decodedData.email,
                name: decodedData.name,
                image: decodedData.picture,
                token: googleData.credential
              }));

              if (props.source === 'Login') {
                history.push('/register/');
              } else if (props.source === 'Register') {
                props.loginSuccess();
              }
            } else {
              props.loginSuccess(responseData);
            }
          }
        } else {
          sendMessage(constants.strings.messages('error', 'network'));
        }
      } else {
        sendMessage(constants.strings.messages('error', 'network'));
      }
    } catch (error) {
      if (isMounted) {
        sendMessage(constants.strings.messages('error', 'network'));
      }
    }

    return () => {
      isMounted = false; // Cleanup on unmount
    };
  }

  return (
    <div className="Google">
      <GoogleLogin
        shape="pill"
        width={200}
        onSuccess={(credentialResponse) => { successLogin(credentialResponse) }}
        onError={() => {
          if (constants.services.devMode) console.log('Google Login Failed')
        }}
        auto_select={true}
      />
    </div>
  )
}

export default Google;