import { useContext, useState, useEffect, useCallback, useRef } from 'react';
import { useParams } from "react-router-dom";

import { Context } from '../../DataStore';

import * as constants from '../exports/constants';
import * as strings from '../../data/strings';

import '../../styles/dashboard/Engagement.scss';

const viewsIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/views.svg`;
const recordingsIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/recordings.svg`;
const responsesIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/responses.svg`;

const Engagement = () => {
  const { store, dispatch } = useContext(Context);
  const params = useParams();

  const [data, setData] = useState([]);

  const fetching = useRef(false);

  const sendMessage = useCallback((data) => {
    const statusCopy = {
      ...store.status,
      message: {
        type: data.type,
        text: data.text
      }
    }
    dispatch({
      type: 'status',
      data: statusCopy
    });
  }, [dispatch, store.status]);

  const buildIcon = (type) => {
    switch (type) {
      case strings.default[store.language].Dashboard.Videos:
        return viewsIcon;
      case strings.default[store.language].Dashboard.Recordings:
        return recordingsIcon;
      case strings.default[store.language].Dashboard.Conversations:
        return responsesIcon;
      default:
        return '';
    }
  }

  const fetchDashboard = useCallback(async () => {
    if (store.deeplink !== undefined && store.deeplink.params.groupId === undefined) {
      fetching.current = true;

      try {
        let data = {
          uuid: store.profile.uuid,
          sessionId: store.session.sessionId
        }
        const url = `${constants.services.url.api}/dashboard/fetch/engagement/`;
        const response = await fetch(url, constants.services.config(data));
        const responseData = await response.json();
        fetching.current = false;

        if (response.ok) {
          if (responseData.status === 'Success') {
            setData(responseData.data);
          } else {
            sendMessage(constants.strings.messages('error', 'network'));
          }
        } else {
          sendMessage(constants.strings.messages('error', 'network'));
        }
      } catch (error) {
        sendMessage(constants.strings.messages('error', 'network'));
      }
    }
  }, [sendMessage, store.profile.uuid, store.session.sessionId, store.deeplink]);

  useEffect(() => {
    if (!data.length && !fetching.current && !constants.utils.isObject(params)) {
      fetchDashboard();
    }
  }, [data.length, fetching, fetchDashboard, params]);

  return (
    <div className="Engagement dashboardModule">
      <div className="moduleWrapper">
        <div className="moduleName">
          {strings.default[store.language].Dashboard.Engagement}
        </div>
        <div className="moduleContent">
          <div className="moduleBox">
            <div className="moduleTitleWrapper">
              <div className="moduleTitle">
                Last 30 days
              </div>
            </div>
            {data.map((asset, i) => (
              <div
                className="assetWrapper"
                key={`Asset${i}`}>
                <div className="assetInner">
                  <div className="assetTitle">
                    <div className="assetImage">
                      <img
                        className="assetIcon"
                        src={buildIcon(strings.default[store.language].Dashboard[asset.type])}
                        alt={strings.default[store.language].Dashboard[asset.type]} />
                    </div>
                    <div className="assetText">
                      {strings.default[store.language].Dashboard[asset.title]}
                    </div>
                  </div>
                  <div className="assetCount">
                    {asset.count}
                  </div>
                  <div className="assetType">
                    {strings.default[store.language].Dashboard[asset.type]}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Engagement;
