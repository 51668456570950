export const email = (value) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
}

export const username = (value) => {
  return /^[a-zA-Z0-9].{2,20}$/.test(value);
}

export const name = (value) => {
  return value.length && value.split(' ').length > 1;
}

export const password = (value) => {
  return /^(?=.*\d)(?=.*[$@$!%* #+=?&])(?=.*[a-z])(?=.*[A-Z]).{6,}$/.test(value);
}

// Validation
export const minimumNumber = (value) => {
  return (value).match(/^(?=.*\d).*$/);
}
export const specialCharacter = (value) => {
  return (value).match(/^(?=.*[$@$!%* #+=?&]).*$/);
}
export const minimumLowercase = (value) => {
  return (value).match(/^(?=.*[a-z]).*$/);
}
export const minimumUppercase = (value) => {
  return (value).match(/^(?=.*[A-Z]).*$/);
}
export const minimumCharacters = (value) => {
  return /^.{6,}$/.test(value);
}

export const match = (value, optional) => {
  return value === optional;
}

export const input = (value, type, optional) => {
  switch (type) {
    case '':
      return true;
    case 'email':
      return email(value);
    case 'username':
      return username(value);
    case 'password':
      return password(value);
    case 'match':
      if (optional !== null) {
        return match(value, optional);
      } else {
        return true;
      }
    default:
      return true;
  }
}
