import { useContext, useState } from 'react';
import { Context } from '../../DataStore';

import * as constants from '../exports/constants';

import '../../styles/common/Participant.scss';

const Participant = (props) => {
  const { store } = useContext(Context);
  const [hovering, setHovering] = useState(false);
  const [seeAll, setSeeAll] = useState(false);

  const additionalConditions = props.display || props.asset;

  const getInitials = () => {
    if (props.participant.name !== undefined && props.participant.name !== '') {
      const nameArray = props.participant.name.trim().split(' ');
      const firstInitial = nameArray.length ? nameArray[0].substring(0, 1).toUpperCase() : '?';
      const lastInitial = nameArray.length ? nameArray[nameArray.length - 1].substring(0, 1).toUpperCase() : '?';
      return `${firstInitial}${lastInitial}`;
    } else {
      return 'A';
    }
  }

  const setHoveringManager = (hovering) => {
    if (props.last === 0) {
      setHovering(hovering);
    }
  }

  return (
    <div className="Participant">
      <div
        className="secondaryAvatar"
        style={{
          transform: additionalConditions ? 'none' : `translateX(${props.i === 0 ? 0 : ((props.i - 1) * -14)}px)`,
          zIndex: additionalConditions ? 'initial' : store.status.space.participants.length - props.i
        }}
        onMouseEnter={() => additionalConditions ? false : setHoveringManager(true)}
        onMouseLeave={() => additionalConditions ? false : setHoveringManager(false)}
      >
        {props.last > 0 ?
          <div className="plusMore">
            <div className="plusNumber">
              <div className="numberText">
                {`+${props.last}`}
              </div>
            </div>
          </div> :
          props.participant.image !== undefined && props.participant.image !== '' ?
            <img
              className="avatar"
              src={constants.services.s3ToCloudfrontMedia(props.participant.image)}
              alt={props.participant.name} /> :
            <div className={`avatar text${props.display ? ' display' : (props.asset ? ' asset' : '')}`}>
              <div className="avatarText">
                {getInitials()}
              </div>
            </div>
        }
        {hovering &&
          <div className="participantName">
            {props.participant.name}
          </div>
        }
        {props.last > 0 &&
          <>
            <button
              className="seeAll"
              onClick={() => setSeeAll(!seeAll)}>
              View All
            </button>
            <div
              className={`participantNames${seeAll ? ' active' : ''}`}
              onMouseLeave={() => setSeeAll(false)}>
              {store.status.space.participants
                .filter(e => e.name !== store.status.space.creator.name)
                .sort((a, b) => (
                  a.name.trim().split(' ')[a.name.trim().split(' ').length - 1].toLowerCase() < b.name.trim().split(' ')[b.name.trim().split(' ').length - 1].toLowerCase()) ? -1 :
                  ((a.name.trim().split(' ')[a.name.trim().split(' ').length - 1].toLowerCase() > b.name.trim().split(' ')[b.name.trim().split(' ').length - 1].toLowerCase()) ?
                    1 : 0))
                .map((participant, i) => {
                  if (i > -1) {
                    return (
                      <div
                        className="participant"
                        key={`Participant${i}`}>
                        {participant.name}
                      </div>
                    )
                  } else {
                    return '';
                  }
                })}
            </div>
          </>
        }
      </div>
    </div>
  );
}

export default Participant;
