import RecentVideos from '../dashboard/RecentVideos';
//import Notifications from '../dashboard/Notifications';
import Engagement from '../dashboard/Engagement';
import QuickMenu from '../dashboard/QuickMenu';

import '../../styles/composite/DashboardViewer.scss';

const DashboardViewer = () => {
  return (
    <div className="DashboardViewer">
      <div className="viewerWrapper">
        <RecentVideos />
        <Engagement />
        <QuickMenu />
      </div>
    </div>
  );
}

export default DashboardViewer;
