import { useEffect, useContext, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { Context } from '../../DataStore';

import * as strings from '../../data/strings';

import '../../styles/dashboard/Tutorials.scss';

const Tutorials = () => {
  const { store, dispatch } = useContext(Context);
  const history = useHistory();

  const tutorials = useMemo(() => [
    {
      tid: 'getting-started',
      title: 'Getting Started',
      description: 'Learn how to record your first video prompt on Gravity!',
      tag: 'POPULAR',
      steps: [
        {
          title: `Let's set it up`,
          description: 'In the Creator Studio, click "Quick Prompt" to record a fast 1-minute prompt to jump-start conversation, or click "Premium Prompt", for unlimited recording time, file attachments, videos, slideshows, and AI-powered prompt ideas.'
        },
        {
          title: `Enter the basics`,
          description: 'To begin, enter in a prompt title and a prompt description.'
        },
        {
          title: `Select prompt type`,
          description: `Next, select what type of prompt you'd like to record: Video, Voice, Screen, Combo (Voice + Screen) or Upload.`
        },
        {
          title: `Permission, please`,
          description: `Your browser may ask you to give Gravity permission to your web camera and microphone - press "Allow".`
        },
        {
          title: `Time to record`,
          description: `Once you are ready, press the Record 🔴 button and the recording will begin after a 3-second countdown.`
        },
        {
          title: `Need some time?`,
          description: `If you need to pause while recording for a moment, simply press the PAUSE button and then the RESUME button to continue recording.`
        },
        {
          title: `Stop when finished`,
          description: `When you are done recording, press the Stop 🟥 button.`
        },
        {
          title: `You're all done!`,
          description: `If everything looks good, press the "Submit" button and that's it!`
        },
      ]
    },
  ], []);

  const assignTutorial = useCallback((tutorial) => {
    const statusCopy = {
      ...store.status,
      tutorials: {
        ...store.status.tutorials,
        content: tutorial
      }
    }
    dispatch({
      type: 'status',
      data: statusCopy
    });
  }, [dispatch, store.status]);

  const openTutorial = (tutorial) => {
    assignTutorial(tutorial);
    history.push(`/t/${tutorial.tid}/`);
  }

  useEffect(() => {
    if (store.deeplink.params.tutorialId !== undefined && store.deeplink.params.tutorialId !== null) {
      if (store.status.tutorials.content === null) {
        const tutorialIndex = tutorials.map((tutorial) => tutorial.tid).indexOf(store.deeplink.params.tutorialId);
        assignTutorial(tutorialIndex > -1 ? tutorials[tutorialIndex] : null);
        if (tutorialIndex === -1) history.push('/t/');
      }
    }
  }, [store.status.tutorials, assignTutorial, history, store.deeplink.params.tutorialId, tutorials]);

  return (
    <div className="Tutorials">
      <div className="backgroundWrapper">
        <div className="background">
          <div className="backgroundImage" />
          <div className="backgroundOverlay" />
          <div className="backgroundTag">
            {`Gravity ${strings.default[store.language].Dashboard.Tutorials}`}
          </div>
          <div className="backgroundText">
            {store.status.tutorials.content === null ? strings.default[store.language].Dashboard.TutorialsDescription : store.status.tutorials.content.title}
          </div>
        </div>
      </div>
      {store.status.tutorials.content === null ?
        <div className="tutorialsWrapper">
          {tutorials.map((tutorial, i) => (
            <div
              className="tutorialInner"
              key={`Tutorial${i}`}>
              <button
                className="tutorial"
                onClick={() => openTutorial(tutorial)}>
                <div
                  className="tutorialImage"
                  style={{ backgroundImage: `url(${process.env.REACT_APP_CF_APP_ENDPOINT}jpg/tutorials/${tutorial.tid}.jpg)` }} />
                {tutorial.tag !== null &&
                  <div className="tutorialTag">
                    {tutorial.tag}
                  </div>
                }
                <div className="tutorialsData">
                  <div className="tutorialTitle">
                    {tutorial.title}
                  </div>
                  <div className="tutorialDesc">
                    {tutorial.description}
                  </div>
                </div>
              </button>
            </div>
          ))}
        </div> :
        <div className="tutorialDisplay">
          {store.status.tutorials.content !== null &&
            <div className="tutorialWrapper">
              <div className="tutorialData">
                <div className="tutorialLongDesc">
                  {store.status.tutorials.content.description}
                </div>
              </div>

              {store.status.tutorials.content.steps.map((step, i) => (
                <div
                  className="stepWrapper"
                  key={`Step${i}`}>
                  <div
                    className="stepImage"
                    style={{ backgroundImage: `url(${process.env.REACT_APP_CF_APP_ENDPOINT}jpg/tutorials/${store.status.tutorials.content.tid}-${i+1}.jpg)` }}>
                  </div>
                  <div className="stepData">
                    <div className="stepNumber">
                      <div className="stepText">
                        <div className="stepStep">
                          {`Step ${i + 1}`}
                        </div>
                        <div className="stepDecoration" />
                      </div>
                    </div>
                    <div className="stepTitle">
                      {step.title}
                    </div>
                    <div className="stepDesc">
                      {step.description}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          }
        </div>
      }
    </div>
  );
}

export default Tutorials;
