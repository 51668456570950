import { useContext } from 'react';
import { Context } from '../../DataStore';

import * as strings from '../../data/strings';

import '../../styles/marketing/CallOut.scss';

const CallOut = () => {
  const { store } = useContext(Context);

  return (
    <div className="CallOut">
      <div className="callOutWrapper">
        <div className="callOut">
          {strings.default[store.language].Marketing.CallOut}
        </div>
      </div>
    </div>
  );
}

export default CallOut;
