import { useContext, useRef } from 'react';
import { Context } from '../../DataStore';

import * as strings from '../../data/strings';
import * as constants from '../exports/constants';

import '../../styles/recorder/Uploader.scss';

const Uploader = (props) => {
  const { store } = useContext(Context);
  const fileUpload = useRef(null);
  const metadata = ['name'/*,'type'*/];

  const fileSelected = (event, file) => {
    const fileNameArr = file.name.split('.');
    const fileType = fileNameArr[fileNameArr.length - 1];

    props.setFileType(fileType);
    props.setUpload(file);
    props.setSelected(true);
    dragCancel(event);
  }

  // Cancel default browser behavior
  const dragCancel = (event) => {
    event.stopPropagation();
    event.preventDefault();
  }

  // Reselect file for upload
  const reselectFile = () => {
    props.setFileType(null);
    props.setUpload(null);
    props.setSelected(false);
  }

  return (
    <div className="Uploader">
      {props.selected ?
      <div className="selectedOptions">
        <div className="selected">
          <div className="fileType">
            <div className="fold"></div>
            <div className="text">
              {props.fileType}
            </div>
          </div>
          <div className="data">
            {metadata.map((field, i) => {
            const display = () => {
              switch(field) {
              case 'type':
                return props.fileType;
              default:
                return (props.upload === null ? '' : props.upload[field]);
              }
            }
            return (
              <div 
                key={`Metadata${i}`}
                className={`metadata ${field}`}>
                <div className="label">
                  {strings.default[store.language].Upload[`File${constants.utils.capitalizeFirst(field)}`]}
                </div>
                <div className="fileTitle">
                  {display()}
                </div>
              </div>
            )})}
          </div>
        </div>
        
        {/* Button options after upload */}
        <div className="buttonGroup">
          <div className="buttonWrapper">
            <button 
              className="button cancel"
              onClick={() => reselectFile()}>
              {strings.default[store.language].Upload.Reselect}
            </button>
          </div>
          <div className="buttonWrapper">
            <button 
              className="button preview"
              onClick={() => props.setFile()}>
              {strings.default[store.language].Upload.SetAsVideo}
            </button>
          </div>
        </div>
     
      </div> :
      <div className="uploadOptions">
        <div 
          className="drop"
          onDragEnter={(event) => dragCancel(event)}
          onDragOver={(event) => dragCancel(event)}
          onDrop={(event) => fileSelected(event, event.dataTransfer.files[0])}>
          <div className="message">
            {strings.default[store.language].Upload.DropFileHere}
            <div className="or">
              {strings.default[store.language].Upload.Or}
            </div>
            {/* File upload button */}
            <input 
              className="input"
              ref={fileUpload}
              type="file"
              accept=".mp4"
              onChange={(event) => fileSelected(event, event.target.files[0])} />
            <div className="buttonWrapper">
              <button 
                className="selectButton"
                onClick={() => fileUpload.current.click()}>
                <span className="icon">📽️</span> {strings.default[store.language].Upload.SelectVideo}
              </button>
            </div>
          </div>
        </div>
      </div>
    }
    </div>
  );
}

export default Uploader;
