export const schools = [
    'asu'
];

export const schooolData = {
    asu: {
        mascot: 'Sun Devils',
        name: 'ASU Preparatory Academy',
        sector: 'k8'
    }
}