import ReactGA from 'react-ga4';
import { useContext, useState, useRef, useEffect, useCallback } from 'react';
import { Context } from '../../DataStore';
import { useHistory, useParams } from "react-router-dom";

import ThreadView from './ThreadView';
import Menu from './Menu';
import Content from './Content';
import Modal from '../common/Modal';

import * as strings from '../../data/strings';
import * as constants from '../exports/constants';

import '../../styles/composite/Dashboard.scss';

const Dashboard = () => {
  if (!constants.services.devMode) {
    ReactGA.send({
      hitType: "pageview",
      page: "/dashboard/",
      title: "Dashboard"
    });
  }

  const { store, dispatch } = useContext(Context);
  const history = useHistory();
  const params = useParams();

  const [closing, setClosing] = useState(false);
  const [loadingDeeplink, setLoadingDeeplink] = useState(false);

  const collapsed = useRef(constants.utils.isMobile());
  const loadedPremium = useRef(false);

  const parsePath = useCallback((deeplink) => {
    dispatch({
      type: 'deeplink',
      data: {
        ...store.deeplink,
        params: deeplink,
        parsed: true
      }
    });
  }, [dispatch, store.deeplink]);

  const sendMessage = (data) => {
    const statusCopy = {
      ...store.status,
      message: {
        type: data.type,
        text: data.text
      }
    }
    dispatch({
      type: 'status',
      data: statusCopy
    });
  };

  const deleteThread = async (threadObj, event) => {
    if (store.status.modal.active) {
      try {
        let data = {
          cid: store.status.community.cid,
          gid: store.status.group.gid,
          sid: store.status.space.sid,
          aid: threadObj.aid,
          uuid: store.profile.uuid,
          sessionId: store.session.sessionId
        }
        const url = `${constants.services.url.api}/asset/modify/deactivate/`;
        const response = await fetch(url, constants.services.config(data));
        const responseData = await response.json();

        if (response.ok) {
          if (responseData.status === 'Success') {
            // store.assets object
            let assetsDataCopy = { ...store.assets.data };
            let assetResponseCopy = [...assetsDataCopy.responses];
            const assetIndex = assetResponseCopy.map((response) => response.aid).indexOf(store.status.asset.aid);
            if (assetIndex > -1) {
              let threadCopy = [...assetResponseCopy[assetIndex].thread];
              let threadIndex = threadCopy.map((thread) => thread.aid).indexOf(threadObj.aid);

              if (threadIndex > -1) {
                threadCopy.splice(threadIndex, 1);
                assetResponseCopy[assetIndex].thread = threadCopy;
              }
              assetsDataCopy.responses = assetResponseCopy;
            }

            // store.status.asset object
            let assetStatusCopy = { ...store.status.asset };
            let assetStatusThreadCopy = [...assetStatusCopy.thread];
            const assetStatusIndex = assetStatusThreadCopy.map((asset) => asset.aid).indexOf(threadObj.aid);

            if (assetStatusIndex > -1) {
              assetStatusThreadCopy.splice(assetStatusIndex, 1);
              assetStatusCopy.thread = assetStatusThreadCopy;
            }

            // Add to DataStore
            const storeCopy = {
              ...store,
              assets: {
                data: assetsDataCopy,
                fetched: true
              },
              status: {
                ...store.status,
                asset: assetStatusCopy,
                modal: {
                  active: false,
                  action: null,
                  type: null,
                  store: null,
                  thread: null,
                  data: {
                    title: null,
                    description: null,
                    inputTitle: null,
                    placeholder: null,
                    privacySetting: false
                  }
                }
              }
            }
            dispatch({
              type: 'store',
              data: storeCopy
            });

            // if no replies then close
          } else {
            sendMessage(constants.strings.messages('error', 'network'));
          }
        } else {
          sendMessage(constants.strings.messages('error', 'network'));
        }
      } catch (error) {
        sendMessage(constants.strings.messages('error', 'network'));
      }
    } else {
      event.persist();
      const statusCopy = {
        ...store.status,
        modal: {
          active: true,
          action: 'delete',
          type: 'confirm',
          store: '',
          thread: null,
          event: event,
          data: {
            title: strings.default[store.language].Menu.ConfirmDeleteResponse,
            description: strings.default[store.language].Menu.ConfirmDeleteDescription,
            inputTitle: threadObj.data.description,
            placeholder: '',
            privacySetting: true
          }
        }
      }
      dispatch({
        type: 'status',
        data: statusCopy
      });
    }
  }

  const promptPremium = useCallback(() => {
    setTimeout(() => {
      const storeCopy = {
        ...store,
        status: {
          ...store.status,
          modal: {
            active: true,
            action: 'premium',
            data: {
              title: store.subscription.active ? strings.default[store.language].Modal.YouArePremium : strings.default[store.language].Modal.PremiumTitle,
              description: store.subscription.active ? `${store.profile.name.split(' ')[0]}, ${strings.default[store.language].Modal.PremiumThankYou} ${strings.default[store.language].Modal.PremiumMember} (${strings.default[store.language].Modal[store.subscription.duration]})!` : strings.default[store.language].Modal.PremiumDescription
            }
          },
          deeplink: {
            type: 'deeplink',
            data: {
              ...store.deeplink,
              premium: false
            }
          }
        },
      }
      dispatch({
        type: 'store',
        data: storeCopy
      });
    }, 500);
  }, [store, dispatch]);

  useEffect(() => {
    if (!constants.utils.isObject(store.profile)) {
      if (constants.utils.isObject(params)) {
        dispatch({
          type: 'deeplink',
          data: {
            ...store.deeplink,
            params: params,
            premium: false
          }
        });
      } else {
        const storeCopy = {
          ...store,
          deeplink: {
            ...store.deeplink,
            premium: history.location.pathname === '/dashboard/premium/'
          },
          status: {
            ...store.status,
            tutorials: {
              active: history.location.pathname.includes('/t/'),
              backFrom: history.location.pathname.includes('/t/'),
              content: null
            }
          }
        }

        dispatch({
          type: 'store',
          data: storeCopy
        });
      }
      history.push('/login/');
    } else {
      if (store.deeplink !== undefined) {
        if (store.deeplink !== undefined && constants.utils.isObject(store.deeplink.params) && !store.deeplink.parsed) {

          const isTutorial = Object.keys(store.deeplink.params)[0] === 'tutorialId';

          let route = `/${isTutorial ? 't' : 'g'}`;
          for (var key of Object.keys(store.deeplink.params)) {
            route = route.concat(`/${store.deeplink.params[key]}`);
          }
          route = route.concat('/');
          parsePath(store.deeplink.params);

          history.push(route);

          const statusCopy = {
            ...store.status,
            tutorials: {
              active: history.location.pathname.includes('/t/'),
              backFrom: history.location.pathname.includes('/t/'),
              content: null
            }
          }

          dispatch({
            type: 'status',
            data: statusCopy
          });
        } else {
          if (store.deeplink.premium && !loadedPremium.current) {
            loadedPremium.current = true;
            promptPremium();
          }
        }
      } else {
        window.location.reload();
      }
    }
  }, [history, store.profile, dispatch, params, store.deeplink, parsePath, promptPremium]);

  return (
    constants.utils.isObject(store.profile) &&
    <div className="Dashboard">
      <div className="dashboardContent">
        {constants.utils.isObject(store.status.asset) &&
          <ThreadView
            closing={closing}
            setClosing={(closing) => setClosing(closing)}
            deleteThread={(response, event) => deleteThread(response, event)} />
        }
        {!store.status?.tutorials?.active &&
          <div className={`menuWrapper${((constants.utils.isObject(store.status.asset) && !closing) || loadingDeeplink) ? ' blurred' : ''}${store.status.menu ? collapsed.current ? constants.utils.isMobile() ? ' collapsed' : ' expanded' : '' : constants.utils.isMobile() ? ' expanded' : ' collapsed'}${(constants.utils.isMobile() && constants.utils.isObject(store.status.space)) ? ' mobileSpace' : ''}`}>
            <Menu
              collapsed={collapsed.current}
              setCollapsed={(collapse) => collapsed.current = collapse}
              setLoadingDeeplink={(loading) => setLoadingDeeplink(loading)} />
          </div>
        }
        <div className={`contentWrapper${store.status.menu ? collapsed.current ? constants.utils.isMobile() ? ' collapsed' : ' expanded' : '' : constants.utils.isMobile() ? ' expanded' : ' collapsed'}${(constants.utils.isMobile() && constants.utils.isObject(store.status.space)) ? ' mobileSpace' : ''}${store.status.tutorials.active ? ' tutorials' : ''}`}>
          <Content
            setLoadingDeeplink={(loading) => setLoadingDeeplink(loading)}
            loadingDeeplink={loadingDeeplink}
            closing={closing} />
        </div>
      </div>
      {store.status?.modal?.active ?
        <Modal /> :
        ''
      }
    </div>
  );
}

export default Dashboard;
