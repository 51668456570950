export const messages = (type, text) => {
  const lookup = () => {
    return {
      success: {
        editProfile: "Profile updated successfully!",
        passwordReset: "If you have an account, you will receive an email with a reset link.",
      },
      warning: {
        email: "Please enter a valid email address and try again.",
        emailValidate: "Email address is not in a valid format.", 
        passwordValidate: "Password must contain 1 Capital, 1 lowercase, 1 number and 1 special character.",
        matchValidate: "Password and Password Confirmation do not match.",
        emailExists: "An account with this email address already exists."
      },
      error: {
        network: "Network Error. Please check your connection.",
        login: "Incorrect email and password combination.",
        fetchData: "Data could not be fetched at this time",
        stripeCreate: "Stripe could not be reached at this time.",
        camera: "Camera had an issue operating.",
        findCommunity: "Community does not exist.",
        editProfile: "Profile could not be updated at this time."
      }
    }
  }
  return {
    type: type, 
    text: (lookup()[type] !== undefined && lookup()[type][text] !== undefined ? lookup()[type][text] : text)
  };
}

export const googleClientID = '918317489922-k4goqnquae3be7geskgvs3pbu7p2f4fv.apps.googleusercontent.com';