import { useContext, useEffect } from 'react';
import { Context } from '../../DataStore';

import '../../styles/common/Message.scss';

const errorIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/error.svg`;
const successIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/success.svg`;
const warningIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/warning.svg`;

const Message = () => {
    const { store, dispatch } = useContext(Context)

    const renderIcon = () => {
        switch (store.status.message.type) {
            case 'success':
                return successIcon
            case 'warning':
                return warningIcon
            case 'error':
                return errorIcon
            default:
                return ''
        }
    }

    useEffect(() => {
        if (store.status.message.type !== null) {
            setTimeout(() => {
                const statusCopy = {
                    ...store.status,
                    message: {
                        type: null,
                        text: null,
                    },
                }
                dispatch({
                    type: 'status',
                    data: statusCopy,
                })
            }, 5000);
        }
    }, [dispatch, store.status]);

    return (
        <div className={`Message${store.status.message.text !== null ? ' active' : ''}`}>
            <div className="messageWrapper">
                <div className="messageImage">
                    <img
                        className="messageIcon"
                        src={renderIcon()}
                        alt="Message status" />
                </div>
                <div className="messageText">
                    {store.status.message.text}
                </div>
            </div>
        </div>
    );
}

export default Message;
