import { useState, useContext, useRef } from 'react';
import { Context } from '../../DataStore';

import Loading from './Loading';

import * as constants from '../exports/constants';

import * as strings from '../../data/strings';

import '../../styles/common/InputEdit.scss';

const SaveIconWhite = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/save.svg`;
const UndoIconWhite = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/undo.svg`;
const DeleteIconWhite = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/delete-white.svg`;

const InputEdit = (props) => {
    const { store, dispatch } = useContext(Context);

    const [value, setValue] = useState(props.value);
    const [action, setAction] = useState('');
    const [modifying, setModifying] = useState(false);

    const inputRef = useRef(props.value);

    const modifyInterface = async (action) => {
        setAction(action);
        setModifying(true);

        try {
            let data = {
                id: props.data.id,
                uuid: store.profile.uuid,
                sessionId: store.session.sessionId
            }

            if (action === 'save') data.title = value;

            const url = `${constants.services.url.api}/${props.data.type}/modification/${action}/`;
            const response = await fetch(url, constants.services.config(data));
            const responseData = await response.json();
            setModifying(false);
            setAction('');

            if (response.ok) {
                if (responseData.status === 'Success') {
                    props.modification(action, props.data.id, value);
                }
            }
        } catch (error) {
            setModifying(false);
            setAction('');
        }
    }

    const leaveCommunity = async () => {
        setModifying(true);

        try {
            let data = {
                cid: props.data.id,
                uuid: store.profile.uuid,
                sessionId: store.session.sessionId
            }

            const url = `${constants.services.url.api}/community/leave/`;
            const response = await fetch(url, constants.services.config(data));
            const responseData = await response.json();
            setModifying(false);

            if (response.ok) {
                let storeCopy = {
                    ...store,
                    communities: {
                        ...store.communities,
                        data: responseData.data
                    },
                    reload: {
                        ...store.reload,
                        communities: {
                            data: responseData.data,
                            modified: true,
                            loaded: false,
                            fetched: true
                        }
                    },
                    status: {
                        ...store.status,
                        modal: {
                            ...store.status.modal,
                            data: {
                                ...store.status.modal.data,
                                communities: responseData.data
                            }
                        }
                    }
                }

                if (store.status.community.cid === props.data.id) storeCopy.status.community = {};
                if (store.status.group.cid === props.data.id) storeCopy.status.group = {};
                if (store.status.space.cid === props.data.id) storeCopy.status.space = {};

                dispatch({
                    type: 'store',
                    data: storeCopy
                });
            }
        } catch (error) {
            setModifying(false);
        }
    }

    const handleCancel = () => {
        setValue(inputRef.current);
    };

    return (
        <div className="InputEdit">
            {props.data.canEdit ?
                <>
                    <input
                        className="editInput"
                        value={value}
                        onChange={(e) => setValue(e.target.value)} />
                    <div className="editButtons">
                        <div className="buttonWrapper">
                            <button
                                className="editButton undo"
                                onClick={() => handleCancel(value)}
                                disabled={value === inputRef.current || modifying}>
                                <img
                                    className="icon"
                                    src={UndoIconWhite}
                                    alt="Undo" />
                            </button>
                        </div>
                        <div className="buttonWrapper">
                            <button
                                className={`editButton save${modifying && action === 'save' ? ' modifying' : ''}`}
                                onClick={() => modifyInterface('save', value)}
                                disabled={value === inputRef.current || modifying}>
                                <img
                                    className={`icon${modifying && action === 'save' ? ' disabled' : ''}`}
                                    src={SaveIconWhite}
                                    alt="Save" />
                                {(modifying && action === 'save') &&
                                    <Loading active={true} />
                                }
                            </button>
                        </div>
                        <div className="buttonWrapper">
                            <button
                                className={`editButton delete${modifying && action === 'delete' ? ' modifying' : ''}`}
                                onClick={() => modifyInterface('delete', value)}
                                disabled={modifying}>
                                <img
                                    className={`icon${modifying && action === 'delete' ? ' disabled' : ''}`}
                                    src={DeleteIconWhite}
                                    alt="Delete" />
                                {(modifying && action === 'delete') &&
                                    <Loading active={true} />
                                }
                            </button>
                        </div>
                    </div>
                </> :
                <>
                    <div className={`ineligibleEdit${props.data.editFolders ? ' folder' : ''}`}>
                        {value}
                    </div>
                    {props.data.editCommunities &&
                        <div className="leaveWrapper">
                            <div className="leaveButton">
                                <div className="loadingWrapper">
                                    {modifying &&
                                        <Loading active={true} />
                                    }
                                </div>
                                <button
                                    className={`editButton${modifying ? ' modifying' : ''}`}
                                    onClick={() => leaveCommunity()}
                                    disabled={modifying}>
                                    {strings.default[store.language].Modal.Leave}
                                </button>
                            </div>
                        </div>
                    }
                </>
            }
        </div>
    );
}

export default InputEdit;
