import React from 'react';

import '../../styles/common/Loading.scss';

const Loading = (props) => {

  return (
    <div className={`Loading${props.active ? ' active' : ''}`}>
      <div className="loadingWrapper">
        <div className="circleWrapper large">
          <div className="halfCircle large"></div>
        </div>
        <div className="circleWrapper small">
          <div className="halfCircle small"></div>
        </div>
      </div>
    </div>
  );
}

export default Loading;
