import { useContext, useState, useEffect } from 'react';
import { Context } from '../../DataStore';

import * as constants from '../exports/constants';

import '../../styles/common/Vote.scss';

const Vote = (props) => {
  const { store, dispatch } = useContext(Context);
  const [existingVotes, setExistingVotes] = useState([]);

  const sendMessage = (data) => {
    const statusCopy = {
      ...store.status,
      message: {
        type: data.type,
        text: data.text
      }
    }
    dispatch({
      type: 'status',
      data: statusCopy
    });
  };

  const registerVote = () => {
    let voteCopy = [...existingVotes];
    const voteIndex = voteCopy.map(vote => vote.uuid).indexOf(store.profile.uuid);

    const modifyReaction = async (action) => {
      try {
        const data = {
          cid: store.status.community.cid,
          gid: store.status.group.gid,
          aid: props.aid,
          uuid: store.profile.uuid,
          sessionId: store.session.sessionId
        }
        const url = `${constants.services.url.api}/vote/${action}/`;
        const response = await fetch(url, constants.services.config(data));
        const responseData = await response.json();

        if (response.ok) {
          if (responseData.status !== 'Success') {
            sendMessage(constants.strings.messages('error', 'network'));
          }
        } else {
          sendMessage(constants.strings.messages('error', 'network'));
        }
      } catch (error) {
        sendMessage(constants.strings.messages('error', 'network'));
      }
    }

    if (voteIndex > -1) {
      voteCopy.splice(voteIndex, 1);
      setExistingVotes(voteCopy);
      modifyReaction('remove');
    } else {
      voteCopy.push({
        creation: new Date(Date.now()),
        image: store.profile.image,
        name: store.profile.name,
        uuid: store.profile.uuid
      })
      setExistingVotes(voteCopy);
      modifyReaction('create');
    }
  }

  useEffect(() => {
    if (props.data !== undefined && props.data.length) {
      setExistingVotes(props.data);
    }
  },[props.data]);

  return (
    <div className="Vote">
      <div className="voteWrapper">
        <button 
          className={`voteButton${existingVotes.map(vote => vote.uuid).indexOf(store.profile.uuid) > -1 ? ' selected' : ''}`}
          onClick={() => registerVote()}>
          {`${existingVotes.length} Vote${existingVotes.length > 1 || existingVotes.length === 0 ? 's' : ''}`}
        </button>
      </div>
    </div>
  );
}

export default Vote;
