export const devMode = (window.location.hostname === 'localhost');


// App URLs
export const url = {
  base: 'https://usegravity.com',
  get api() {
    return (devMode ? 'http://localhost:8000' : 'https://usegravity.herokuapp.com')
  }
};

// NOTE: Has direct dev s3 endpoint for testing
export const awsLocation = (forceProd) => {
  return `https://${devMode ? (forceProd ? 'dmn09ay7q1luk.cloudfront.net' : 'dev-gravity-media.s3.amazonaws.com') : 'dmn09ay7q1luk.cloudfront.net'}`;
}

// Fetch templates
export const config = (data, type) => (
  {
    method: (type ?? 'POST'),
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json'
    }
  }
);

export const getConfig = (data) => (
  {
    method: 'GET',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json'
    }
  }
);

export const s3ToCloudfrontMedia = (url) => {
  return url.replace('https://gravity-media.s3.amazonaws.com/', 'https://dmn09ay7q1luk.cloudfront.net/');
};

export const endSession = () => {
  if (localStorage.getItem('gravitySession') !== null) {
    localStorage.removeItem('gravitySession');
  }
};

export const translateLanguages = [
  { label: 'English', value: 'en-US' },
  { label: 'Spanish', value: 'es-ES' },
  { label: 'French', value: 'fr-FR' },
  { label: 'German', value: 'de-DE' },
  { label: 'Italian', value: 'it-IT' },
  { label: 'Portuguese', value: 'pt-PT' },
  { label: 'Dutch', value: 'nl-NL' },
  { label: 'Russian', value: 'ru-RU' },
  { label: 'Japanese', value: 'ja-JP' },
  { label: 'Korean', value: 'ko-KR' },
  { label: 'Chinese', value: 'zh-CN' },
  { label: 'Arabic', value: 'ar-SA' },
  { label: 'Hindi', value: 'hi-IN' }
];