import ReactGA from 'react-ga4';
import { useReducer, useRef } from 'react';
import { Context, state, reducer } from './DataStore';

import * as constants from './components/exports/constants';

import Application from './components/composite/Application';

import './App.scss';
import './styles/exports/variables.scss';

function App() {
  if (!constants.services.devMode) {
    ReactGA.initialize('G-M77G7NWQ36');
  }

  const [store, dispatch] = useReducer(reducer, state);
  const loadedLanguage  = useRef(false);

  // force https
  if (!constants.services.devMode && window.location.protocol !== "https:") {
    window.location.protocol = "https:";
  }

  // Set browser language
  const languageArray = window.navigator.language.split('');
  if (!loadedLanguage.current && languageArray.length > 1) {
    loadedLanguage.current = true;
    const detectedLanguage = `${languageArray[0]}${languageArray[1]}`;
    const languages = ['en','zh','nl'];

    dispatch({
      type: 'language',
      data: ((languageArray.length > 1 ? (languages.includes(detectedLanguage) ? detectedLanguage : 'en') : 'en')),
    });
  }

  return (
    <Context.Provider value={{store, dispatch}}>
      <div className="App">   
        <Application /> 
      </div>
    </Context.Provider>
  );
}

export default App;
