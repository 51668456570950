import { useState, useContext } from 'react';
import { Context } from '../../DataStore';

import Participant from '../common/Participant';

import * as strings from '../../data/strings';
import * as constants from '../exports/constants';

import '../../styles/common/Member.scss';

const DefaultAvatar = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/default-robot.svg`;
const CrownIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/crown.svg`;

const Member = (props) => {
    const { store, dispatch } = useContext(Context);
    const [hovering, setHovering] = useState(false);
    const [removing, setRemoving] = useState(false);

    const deleteMember = async (event) => {
        if (store.status.modal.active) {
            setRemoving(true);
            try {
                let data = {
                    id: store.status.community.cid,
                    muuid: props.member.uuid,
                    uuid: store.profile.uuid,
                    sessionId: store.session.sessionId
                }

                const url = `${constants.services.url.api}/community/modification/member/`;
                const response = await fetch(url, constants.services.config(data));
                const responseData = await response.json();
                setRemoving(false);

                if (response.ok) {
                    if (responseData.status === 'Success') {
                        let membersCopy = [
                            ...store.status.members
                        ]
                        const memberIndex = membersCopy.map((member) => member.uuid).indexOf(props.member.uuid);
                        if (memberIndex > -1) {
                            membersCopy.splice(memberIndex, 1);

                            const storeCopy = {
                                ...store,
                                status: {
                                    ...store.status,
                                    modal: {
                                        active: false,
                                        action: null,
                                        type: null,
                                        store: null,
                                        thread: null,
                                        data: {
                                            title: null,
                                            description: null,
                                            inputTitle: null,
                                            placeholder: null,
                                            privacySetting: false
                                        }
                                    },
                                    members: membersCopy
                                }
                            }

                            dispatch({
                                type: 'store',
                                data: storeCopy
                            });
                        }
                    }
                }
            } catch (error) {
                setRemoving(false);
            }
        } else {
            event.persist();
            const statusCopy = {
                ...store.status,
                modal: {
                    active: true,
                    action: 'delete',
                    type: 'confirm',
                    store: '',
                    thread: null,
                    event: event,
                    data: {
                        type: 'member',
                        title: strings.default[store.language].Menu.ConfirmRemoveMember,
                        description: strings.default[store.language].Menu.ConfirmRemoveMemberDescription,
                        description2: strings.default[store.language].Menu.ConfirmRemoveMemberDescription2,
                        inputTitle: props.member.name,
                        privacySetting: true
                    }
                }
            }
            dispatch({
                type: 'status',
                data: statusCopy
            });
        }
    }

    return (
        <div className="Member">
            <div
                className="member"
                onMouseEnter={() => setHovering(true)}
                onMouseLeave={() => setHovering(false)}
            >
                <div className="avatarWrapper">
                    {store.status.group.creator === props.member.uuid &&
                        <img
                            className="creatorCrown"
                            src={CrownIcon}
                            alt="Speaker" />
                    }
                    {props.member.image === undefined || props.member.image === '' ?
                        <Participant
                            participant={{
                                name: props.member.name
                            }}
                            display
                            list /> :
                        <div
                            className="avatar"
                            style={{ backgroundImage: `url(${props.member.image === '' ? DefaultAvatar : constants.services.s3ToCloudfrontMedia(props.member.image)})` }} />
                    }
                </div>
                <div className="memberData">
                    <div className="name">
                        {props.member.name !== undefined ? props.member.name : strings.default[store.language].SpacesView.Anonymous}
                    </div>
                    <div className="joined">
                        {`${strings.default[store.language].SpacesView.Joined}${store.language !== 'zh' ? ' ' : ''}${constants.utils.parseMonthYear(props.member.creation)}`}
                    </div>
                </div>
                {store.status.community.creator === store.profile.uuid &&
                <div className="deleteWrapper">
                    <button
                        className={`memberDelete${(hovering || removing) ? ' visible' : ''}`}
                        onClick={(event) => deleteMember(event)}>
                        -
                    </button>
                </div>
                }
            </div>
        </div>
    );
}

export default Member;
