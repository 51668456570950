//const globeIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/globe.svg`;
//const keyIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/key.svg`;

import '../../styles/common/Switch.scss';

const Switch = (props) => {
  /*const renderIcon = () => {
    switch(props.type) {
      case 'public':
      return (
        <div className="publicWrapper">
          <img 
            className="publicIcon"
            src={globeIcon}
            alt="Public" />
        </div>
      )
      case 'code':
      return (
        <div className="accessWrapper">
          <img 
            className="accesssIcon"
            src={keyIcon}
            alt="Access code" />
        </div>
      )
      default: 
      return (
        <div className="lockWrapper">
          <div className="lockTop"></div>
          <div className="lockBlock"></div>
          <div className="lockBody">
            <div className="bodyKey"></div>
          </div>
        </div>
      )
    }
  }*/

  return (
    <div className={`Switch${props.className !== undefined ? ` ${props.className}` : ''}`}>
      {props.off !== undefined &&
        <div className="offLabel">
          OFF
        </div>
      }
      <button
        disabled={props.disabled ?? false}
        className={`switchButton${props.boolean ? ' active' : ''}`}
        onClick={() => props.onClick()}>
        <div className={`switchWrapper ${props.boolean ? 'enabled' : 'disabled'}`}>
          <div className="switch"></div>
        </div>
      </button>
      {/*<div className="icon">
        {renderIcon()}
      </div>*/}
    </div>
  );
}

export default Switch;
