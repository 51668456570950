const Youtube = (props) => {
    return (
        <iframe
            width={props.width} height={props.height}
            src={`https://www.youtube.com/embed/${props.youtubeID}`}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen />
    );
}

export default Youtube;
