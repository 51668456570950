import '../../styles/common/Footer.scss';

const Footer = () => {
  return (
    <div className="Footer">
      <div className="content">
        <div className="copyright">
          {`© ${new Date().getFullYear()} Gravity - All rights reserved`}
        </div>
        <div className="version">
          Ver. 3.3.0
        </div>
      </div>
    </div>
  );
}

export default Footer;
