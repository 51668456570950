import '../../styles/marketing/Testimonials.scss';

const Testimonials = (props) => {

  const testimonials = [
    {
      image: 'txstate',
      school: 'Texas State University',
      credential: 'Dr. William T Chittenden, Finance & Economics',
      youtube: 'FjoeTR6CI8M'
    },
    {
      image: 'navasota',
      school: 'Navasota ISD',
      credential: 'Cyndy Harrison, AP US Government & Politics',
      youtube: 'XgTTY5RMvQ8'
    }
  ]

  return (
    <div className="Testimonials">
      <div className="moduleWrapper">
        {(props.sector !== '' ? testimonials.reverse() : testimonials).map((data, i) => (
          <div 
            className="video"
            key={`Testimonial${i}`}>
            <div className="school">
              <img
                className="schoolLogo"
                src={`${process.env.REACT_APP_CF_MKT_ENDPOINT}png/${data.image}.png`}
                alt={data.school} />
            </div>
            <div className="credentials">
              {data.credential}
            </div>
            <iframe width="420" height="235" src={`https://www.youtube.com/embed/${data.youtube}`} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Testimonials;
